import dayjs from 'dayjs';
import Multiselect from 'vue-multiselect';
import { mapGetters } from 'vuex';
import { userIsMemberOfProject } from '@/app/helpers';
import VersionSelect from "@/views/common/VersionSelect.vue";
import RangeSelect from "@/views/common/RangeSelect.vue";

const defaultData = () => {
    return {
        project: null,
        versions: [],
        versionsAll: [],
        range: [dayjs().subtract(6, 'month').toDate(), new Date()],
        userProjectTimes: [],
    };
};

export default {
    name: 'ProjectTime',
    components: {
        Multiselect,
        VersionSelect,
        RangeSelect,
    },
    data: defaultData,
    computed: {
        // @ts-ignore
        ...mapGetters({
            user: 'Resource/auth/user',
            projectsAll: 'Project/list/projects',
            resources: 'Resource/list/resourcesAll',
        }),
        projects() {
            if (this.user.admin) return this.projectsAll;
            return this.projectsAll.filter(project => userIsMemberOfProject(this.user, project));
        },
        total() {
            return this.userProjectTimes.reduce((ac, u) => {
                ac += parseFloat(u.hours);
                return ac;
            }, 0);
        },
    },
    methods: {
        selectProject() {
            this.versions = defaultData().versions;
            if (!this.project) return this.versionsAll = [];
            const projectId = this.project.id;
            this.$store.dispatch("Issue/list/getVersions", projectId).then(versions => {
                this.versionsAll = versions;
            });
            this.refreshProjectTime();
        },
        selectVersion() {
            this.refreshProjectTime();
        },
        refreshProjectTime() {
            if (!this.project) return;
            const payload = {
                projectId: this.project.id,
                params: {
                    versionIds: this.versions.map(ver => ver.id),
                    from: this.range[0],
                    to: this.range[1],
                }
            };
            this.$store.dispatch("ProjectTime/userForProject", payload).then(userProjectTimes => {
                userProjectTimes.forEach((r) => {
                    r.user = this.resources.find(u => u.id == r.userId);
                });
                this.userProjectTimes = userProjectTimes;
            });
        },
    },
    watch: {
        range() {
            this.refreshProjectTime();
        },
    },
    mounted() {
        this.$store.dispatch('Project/list/getList');
    },
}
